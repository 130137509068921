import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
	static targets = ["container", "notes", "selection", "number"]

	connect() {
		this.containerTarget.hidden = true
		// this.loadDoses(0, "loadSelection")
	}

	// show corresponding adverse events note by selecting different dose
	handleChange() {
		this.loadDoses(this.selectionTarget.selectedOptions[0].dataset.index)
	}

	loadDoses(index, action) {
		const { requestUrl } = this.notesTarget.dataset
		Rails.ajax({
			type: 'GET',
			url: requestUrl,
			dataType: 'json',
			success: ({ doses }) => {
				this.notesTarget.value = doses[index]['adverse_events']
				this.numberTarget.innerHTML = parseInt(index) + 1
				// To load updated dose name selection when General Notes page loaded
				if (action) {
					this.loadSelection(doses)
				}

				// if first dose completed, show adverse events section
				this.showElement(doses[0])
			}
		})
	}

	// update dose adverse events field data
	save(event) {
		const { requestUrl } = this.containerTarget.dataset
		const { field } = event.currentTarget.dataset
		const { index } = this.selectionTarget.selectedOptions[0].dataset
		const value = event.currentTarget.value
		Rails.ajax({
			type: 'PUT',
			url: requestUrl,
			data: `vaccination[index]=${index}&vaccination[field]=${field}&vaccination[value]=${value}`
		})
	}

	loadSelection(elements) {
		// clear existing selection before adding updated options
		this.selectionTarget.innerHTML = ""

		elements.map((element, index) => {
			const option = document.createElement('option')
			option.value = element['name']
			option.text = `#${index + 1} - (${element['name']})`
			option.dataset.index = index
			this.selectionTarget.add(option)
		})
	}

	showElement(dose) {
		const completed = !["name", "batch_no", "dose_number", "exp_date"].map(e => dose[e] !== "").includes(false)
		if (completed) {
			this.containerTarget.hidden = false
		}
	}
}
