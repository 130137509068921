import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
	static values = { addingNewDrugScreen: Boolean };
  static targets = ["item", "note", "activityTemplateRow", "drugScreenRow", "carePlan"];

	connect() {
    this.addingNewDrugScreenValue = false;
	}

	saveFormElement() {
    if(this.addingNewDrugScreenValue) return;

    const url = this.element.dataset.url;
    Rails.ajax({
      type: "PUT",
      url: url,
      data: new FormData(this.element),
    });
  }

  addNewCarePlan(event) {
		const consultationPageId = event.currentTarget.dataset.consultationPageId;
    let url = new URL("/care_plans/new", window.location.origin);
    url.searchParams.append("consultation_page_id", consultationPageId);
    url.searchParams.append("plan_number", this.carePlanTargets.length + 1);

    fetch(url, { headers: { accept: "text/html" }, method: "GET" })
      .then((response) => response.text())
      .then((data) => {
        document.getElementById("care-plan-form").innerHTML += data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  addNewActivity(_event) {
    const table = this.activityTemplateRowTarget.closest("table");
    const clonedElement = this.cloneItem(this.activityTemplateRowTarget);
    table.insertAdjacentHTML("beforeend", clonedElement.outerHTML);
  }

  addNewDrugscreen(event) {
    this.addingNewDrugScreenValue = true;
    const table = this.drugScreenRowTarget.closest("table");
    const clonedElement = this.cloneItem(this.drugScreenRowTarget);
    table.insertAdjacentHTML("beforeend", clonedElement.outerHTML);
    this.addingNewDrugScreenValue = false;
  }

  cloneItem(item) {
    const clonedElement = item.cloneNode(true);

    clonedElement.querySelectorAll('input, select').forEach(element => {
      if(element.type == 'checkbox' || element.type == 'radio') {
        element.removeAttribute('checked');
      } else if(element.type == 'select-one') {
        element.querySelectorAll('option').forEach(option => {
          option.removeAttribute('selected');
        });
      } else {
        element.setAttribute('value', '');
        element.value = '';
      }
    });
    return clonedElement;
  }

  cptCodeMappings() {
    return {
      'H0025': 60,
      '90832': 30,
      '90834': 45,
      '90837': 60,
      '90839': 60,
      '90846': 60,
      '90847': 60,
      '90853': 60,
      'H2015': 15,
      'H0038': 15
    };
  }

  calculateTotalMins(event) {
    const table = event.target.closest("table");
    const units = table.querySelector(".js-number-of-units").value;
    const cptCode = table.querySelector(".js-selected-cpt-code").value;
    if(!units || !cptCode || !this.cptCodeMappings()[cptCode]) {
      table.querySelector(".js-total-mins").value = "";
      return;
    }

    table.querySelector(".js-total-mins").value = parseInt(units) * this.cptCodeMappings()[cptCode];
    this.saveFormElement();
  }

  calculateTotalDosage(event) {
    const table = event.target.closest("table");
    const totalDoses = table.querySelector(".js-total-doses").value;
    const dosage = table.querySelector(".js-dosage").value;
    if(!totalDoses || !dosage) {
      table.querySelector(".js-total-dosage").value = "";
      return;
    }

    table.querySelector(".js-total-dosage").value = parseFloat(totalDoses) * parseFloat(dosage);
    this.saveFormElement();
  }

  updateActivities(_event) {
    const consultation_page = new FormData();
    const url = this.element.dataset.url;
    this.activityTemplateRowTargets.forEach((row) => {
      const activity = {};
      const inputs = row.querySelectorAll("input, select");
      inputs.forEach((input) => {
        activity[input.name] = input.value;
      });
      consultation_page.append("consultation_page[activities][]", JSON.stringify(activity));
    });
    Rails.ajax({
      type: "PUT",
      url: url,
      data: consultation_page,
    });
  }

  updateDrugScreens(_event) {
    const consultation_page = new FormData();
    const url = this.element.dataset.url;
    this.drugScreenRowTargets.forEach((row) => {
      const item = {};
      const inputs = row.querySelectorAll("input, select");
      inputs.forEach((input) => {
        item[input.name] = input.type == "checkbox" ? input.checked : input.value;
      });
      consultation_page.append("consultation_page[results.drug_screens][]", JSON.stringify(item));
    });
    Rails.ajax({
      type: "PUT",
      url: url,
      data: consultation_page,
    });
  }

  saveCarePlan(event) {
    const consultation_page = new FormData();
    const url = this.element.dataset.url;
    this.carePlanTargets.forEach((row) => {
      const item = {};
      const inputs = row.querySelectorAll("input, select");
      inputs.forEach((input) => {
        item[input.name] = input.value;
      });
      consultation_page.append("consultation_page[care_plan.plans][]", JSON.stringify(item));
    });
    Rails.ajax({
      type: "PUT",
      url: url,
      data: consultation_page,
    });
  }
}
